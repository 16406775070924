<script lang="ts" setup>

const props = defineProps({
	magnetRadius: {
		type: Number,
		default: 30
	},
	num: {
		type: Number,
		default: 28
	},
	diameter1: {
		type: Number,
		default: 77
	},
	diameter2: {
		type: Number,
		default: 124
	},
	diameterMult: {
		type: Number,
		default: 0.9
	},
	lineWidth: {
		type: Number,
		default: 5
	},
	animateColor: {
		type: Boolean,
		default: false
	},
	color: {
		type: String,
		default: "#000000"
	}
})

const canvas = ref<any>(null)

onMounted(()=>{
	console.log('onMounted')
	const lerpColor = (pFrom:number, pTo:number, pRatio:number)=> {
    const ar = (pFrom & 0xFF0000) >> 16,
          ag = (pFrom & 0x00FF00) >> 8,
          ab = (pFrom & 0x0000FF),

          br = (pTo & 0xFF0000) >> 16,
          bg = (pTo & 0x00FF00) >> 8,
          bb = (pTo & 0x0000FF),

          rr = ar + pRatio * (br - ar),
          rg = ag + pRatio * (bg - ag),
          rb = ab + pRatio * (bb - ab);
		//return	`#${((rr << 16) + (rg << 8) + (rb | 0)).toString(16).padStart(6, '0').slice(-6)}`
    return (rr << 16) + (rg << 8) + (rb | 0);
}

const toHex =(color:number): string => {
  return `#${color.toString(16).padStart(6, '0').slice(-6)}`
}

const toHexNumber = (color: string): number=> {
	return parseInt(color.replace(/#/g, ''), 16)
}

const startColor = toHexNumber(props.color)
const colors8 = [0xe6261f, 0xeb7532, 0xf7d038, 0xa3e048, 0x49da9a, 0x34bbe6, 0x4355db, 0xd23be7]
const colors:number[] = []
const step = Math.ceil(props.num / 8)
for(let ci=0;ci<colors8.length-1; ci++){
	const index = step*ci
	const a = colors8[ci] 
	const b = colors8[ci+1]
	for(let s=0;s<step;s++){
		colors.push(lerpColor(a, b, s/step))
	}
}

const getDistance = (x1:number, y1:number, x2:number, y2:number):number=> {
	const y = x2 - x1;
	const x = y2 - y1;
	return Math.sqrt(x * x + y * y);
}

	let mouseX:number = 0.0
	let mouseY:number = 0.0

	const context = canvas.value.getContext('2d');
	canvas.value.width = canvas.value.height  = (props.diameter2 + (props.magnetRadius*3))

	const centerX = canvas.value.width / 2;
	const centerY = canvas.value.height / 2;

	context.lineWidth = props.lineWidth;
	context.lineCap = "round";
	context.strokeStyle = props.color;
	let angle = 0;
		

	// Listen for mouse movement on the canvas
	canvas.value.addEventListener('mousemove', (e:any) => {
		const rect = e.target.getBoundingClientRect();
		mouseX = e.clientX-rect.left;
		mouseY = e.clientY-rect.top;//why is there an offset???
		//console.log(e)
		update()
	});

	canvas.value.addEventListener('mouseleave', (e:any) => {
		mouseX = centerX;
		mouseY = centerX;
		//console.log(e)
		update()
	});

	const update = () => {
		context.clearRect(0, 0, canvas.value.width, canvas.value.height);
		for (let i = 0; i < props.num; i++) {
			angle += (Math.PI * 2) / props.num;

			// Get the coordinates of the current line
			const d2 = i%2 == 0 ? props.diameter2 * props.diameterMult : props.diameter2
			const x1 = props.diameter1 / 2 * Math.cos(angle) + centerX;
			const y1 = props.diameter1 / 2 * Math.sin(angle) + centerY;
			const x2 = d2 / 2 * Math.cos(angle) + centerX;
			const y2 = d2 / 2 * Math.sin(angle) + centerY;

			// Check the distance between the mouse and the line
			const dist = getDistance(x2, y2, mouseX, mouseY);
			/*
			if(i==0){
				context.lineWidth = 1;
				context.beginPath();
				context.moveTo(x2, y2);
				context.lineTo(this.mouseX, this.mouseY);
				context.stroke();
				console.log(dist)
	
			}
			context.lineWidth = 4;*/
			
					// If the distance is less than the magnet radius, apply the magnet effect
			context.beginPath();
			if(props.animateColor)context.strokeStyle = toHex(lerpColor(colors[i], startColor, Math.min(1, dist/props.magnetRadius)))

			if (dist < props.magnetRadius) {
				// Calculate the new length of the line based on the distance
				const newLength = d2 / 2 + (props.magnetRadius - dist);

				// Draw the new line with the increased length
				//context.strokeStyle = props.color;
				//
				context.moveTo(x1, y1);
				context.lineTo(newLength * Math.cos(angle) + centerX, newLength * Math.sin(angle) + centerY);
		
			} else {
				// Draw the line normally if the distance is greater than the magnet radius
				//context.strokeStyle = props.color// colors[i]
				//console.log(colors[i])
				context.moveTo(x1, y1);
				context.lineTo(x2, y2);
			}
			context.stroke();
		}
	} 
	update()
})

</script>

<template>
    <canvas ref="canvas" class="touch-none"></canvas>
		<!-- <p>	w:{{ lineWidth }}</p> -->
</template>